<template>
  <Index msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Index from "./components";

export default {
  name: 'App',
  components: {
    Index
  }
}
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
}
#app {

}
</style>
