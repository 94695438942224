<template>
  <div class="index">
    <div class="header">
      <div class="headerbox">
        <div class="headerwapper">
          <div class="logo"></div>
          <div class="menuslist">
            <div class="menusbox " :class="menustype ==0 ? 'active' : ''" @click="fatherMethod(0)">官网首页</div>
            <div class="menusbox" :class="menustype ==1 ? 'active' : ''" @click="fatherMethod(1)">解决方案</div>
            <div class="menusbox" :class="menustype ==2 ? 'active' : ''" @click="fatherMethod(2)">我要定制</div>
            <div class="menusbox" :class="menustype ==3 ? 'active' : ''" @click="fatherMethod(3)">FQA</div>
            <div class="menusbox" :class="menustype ==4 ? 'active' : ''" @click="fatherMethod(4)">公司简介</div>
            <div class="menusbox" :class="menustype ==5 ? 'active' : ''" @click="fatherMethod(5)">企业文化</div>
            <div class="menusbox" :class="menustype ==6 ? 'active' : ''" @click="fatherMethod(6)">公司地址</div>
            <div class="menusbox" :class="menustype ==7 ? 'active' : ''" @click="fatherMethod(7)">联系我们</div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="bannerimg">
        <img src="../assets/images/img27.jpg" />
      </div>
    </div>
    <div class="home">
      <div class="homebox">
        <div class="wapper">
          <div class="title">安全、可靠、持续创新的产品与服务</div>
          <div class="des">8年行业沉淀，集结专业团队从行业调研分析到技术选型和技术开发</div>
          <div class="des2">结合市场需求通过产品创新实现数字化升级 </div>
          <div class="linebox">
            <div class="line"></div>
          </div>
          <div class="boxlist">
            <div class="boxflex">
              <div class="boxflexicon1"></div>
              <div class="boxflextitle">线上售楼处</div>
            </div>
            <div class="boxflex">
              <div class="boxflexicon2"></div>
              <div class="boxflextitle">拓客工具包</div>
            </div>
            <div class="boxflex">
              <div class="boxflexicon3"></div>
              <div class="boxflextitle">数字化跟进</div>
            </div>
            <div class="boxflex">
              <div class="boxflexicon4"></div>
              <div class="boxflextitle">
                下沉案场管理
              </div>
            </div>
          </div>
          <div class="" id="box1">
            <div class="title2">全栈解决方案</div>
            <div class="des">细化、精准的完成方案，节约多项开支成本</div>
            <div class="des2"></div>
            <div class="linebox">
              <div class="line"></div>
            </div>
          </div>

          <div class="statuylist">
            <div class="statuylistbox">
              <div class="listboximgicon listboximgicon1">
                <div class="sailm">
                  <div class="sailemicon1"></div>
                </div>
                <div class="listfooter">
                  微网超市
                </div>
                <div class="boxmeng">
                  <div class="mengpadding">
                    <div class="mengtitle">
                      独立自主运营的电商线上购物平台,源头厂家进货，品质保证，价格无忧。
                    </div>
                    <div class="mengline"></div>
                  </div>
                </div>
              </div>
              <div class="listboximgicon listboximgicon2">
                <div class="sailm">
                  <div class="sailemicon2"></div>
                </div>
                <div class="listfooter">
                  微网餐饮
                </div>
                <div class="boxmeng">
                  <div class="mengpadding">
                    <div class="mengtitle">
                      待运用上线
                    </div>
                    <div class="mengline"></div>
                  </div>
                </div>
              </div>
              <div class="listboximgicon listboximgicon3">
                <div class="sailm">
                  <div class="sailemicon3"></div>
                </div>
                <div class="listfooter">
                  微网社交
                </div>
                <div class="boxmeng">
                  <div class="mengpadding">
                    <div class="mengtitle">
                      待运用上线
                    </div>
                    <div class="mengline"></div>
                  </div>
                </div>
              </div>
              <div class="listboximgicon listboximgicon4">
                <div class="sailm">
                  <div class="sailemicon4"></div>
                </div>
                <div class="listfooter">
                  微网地产
                </div>
                <div class="boxmeng">
                  <div class="mengpadding">
                    <div class="mengtitle">
                      待运用上线
                    </div>
                    <div class="mengline"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" id="box2">
            <div class="title2">我要定制</div>
            <div class="des">属于个性化业务，需要业务定制，我们依然用完善的服务体系去服务每个客户，</div>
            <div class="des2">可以定制的范围覆盖APP开发、小程序开发和Web系统开发 </div>
            <div class="linebox">
              <div class="line"></div>
            </div>
          </div>


          <div class="servicelist">
            <div class="serviceicon"></div>
            <div class="serviceright">
              <div class="righttitle">
                APP开发
              </div>
              <div class="rightdexs">
                <p>
                  基于企业业务定制开发APP，实 现源代码交付 </p>

              </div>
              <div class="righttitle">
                小程序开发
              </div>
              <div class="rightdexs">
                <p>
                  通过微信传播获客引流至企业 </p>

              </div>
              <div class="righttitle">
                web系统开发
              </div>
              <div class="rightdexs">
                <p>
                  企业内部管理系统如OA、CRM系 统定制和开发 </p>
              </div>
            </div>
          </div>
          <div class="" id="box3">
            <div class="title2">FAQ您关注的相关问题</div>
          </div>
          <div class="des">
          </div>
          <div class="des2"> </div>
          <div class="linebox">
            <div class="line"></div>
          </div>
          <div class="peoplelist">
            <div class="peolplebox">
              <div class="peoplename">1.我们的优势有哪些</div>
              <div class="zhileist">
                <div class="zhibox">
                  <div class="zhiboxicon"></div>
                  <div class="zhidestitle">我们有强大的专业开发团队，以及高级架构师,UI设计团队。 </div>
                </div>

              </div>
            </div>
            <div class="peolplebox">
              <div class="peoplename">2.商业合作后免费服务周期多久</div>
              <div class="zhileist">
                <div class="zhibox">
                  <div class="zhiboxicon"></div>
                  <div class="zhidestitle">商业项目明确后,我们将免费为客户提供半年的有效运维维护周期。</div>
                </div>
              </div>
            </div>
            <div class="peolplebox">
              <div class="peoplename">3.除了技术开发还能做什么</div>
              <div class="zhileist">
                <div class="zhibox">
                  <div class="zhiboxicon"></div>
                  <div class="zhidestitle">还可以为创业公司提供专业的技术方案，以及项目运营方案。 </div>
                </div>

              </div>
            </div>
            <div class="peolplebox">
              <div class="peoplename">4.是否可以满足企业业务调整的效率需求</div>
              <div class="zhileist">
                <div class="zhibox">
                  <div class="zhiboxicon"></div>
                  <div class="zhidestitle">我们能够高效的满足企业业务的调整。 </div>
                </div>

              </div>
            </div>
            <div class="peolplebox">
              <div class="peoplename">5.定制开发是源码交付吗</div>
              <div class="zhileist">
                <div class="zhibox">
                  <div class="zhiboxicon"></div>
                  <div class="zhidestitle">项目交付资料有前后端源码，数据库，需求清单以及UI源文件等相关资料。 </div>
                </div>

              </div>
            </div>
            <div class="peolplebox">
              <div class="peoplename">6.技术革新在哪些方面领先</div>
              <div class="zhileist">
                <div class="zhibox">
                  <div class="zhiboxicon"></div>
                  <div class="zhidestitle">我们采用最先进的软件开发平台，包揽万象的专业团队。 </div>
                </div>

              </div>
            </div>
          </div>
          <div class="" id="box4">

            <div class="title2">公司简介</div>
          </div>
          <div class="linebox">
            <div class="line"></div>
          </div>
          <div class="cpompany">
            <div class="conpmayicon"></div>
            <div class="conpmades">
              微讯（定州）网络科技有限公司成立于2021年，始终致力于在信息技术领域发展。秉承“创新、求实、诚信、拼搏”的企业精神，致力为企业提供全面的网络宣传与技术应用整体策划方案，真正实现企业互联网信息智能化，提高企业在网络科技时代的市场竞争力。
            </div>
          </div>
          <div class="" id="box5">
            <div class="title2">企业文化</div>
          </div>
          <div class="linebox">
            <div class="line"></div>
          </div>
          <div class="wenhua">
            <div class="wenhuabox wenhuaboxpos">
              <div class="wenhuaboxleft">
                <div class="lefttitle">以科技创新服务实体经济</div>
                <div class="leftlist">
                  <div class="leftlisttitle">打破常规，光速前进；</div>
                  <div class="leftlisttitle">先迈一步，勤俭守契；</div>
                  <div class="leftlisttitle">善待用户，尊重工作；</div>
                  <div class="leftlisttitle">数据管理，专业主义；</div>
                </div>
              </div>
              <div class="wenhuaboximg"></div>
            </div>
            <div class="wenhuabox wenhuaboxmt">
              <div class="wenhuaboximg2"></div>
              <div class="wenhuaboxleft wenhuaboxleftpm ">
                <div class="lefttitle">走进微讯科技</div>
                <div class="leftlist listfendes">
                  公司旗下独自运营的的平台有：微网商城，我们可以保证每一个产品都是安全可靠的正品，拒绝假冒伪劣商品.
                </div>
              </div>

            </div>
          </div>
          <div class="" id="box6">
            <div class="title2">公司地址</div>
          </div>
          <div class="linebox">
            <div class="line"></div>
          </div>
          <div class="map"></div>
          <div class="mapdes">公司地址：河北省定州市北城区东大街</div>
        </div>
      </div>
    </div>
    <div class="footer" id="box7">
      <div class="footerbox">
        <div class="box">
          <div class="boxleft">
            <div class="lefttitle">关于我们</div>
            <div class="lefttitle">地址：河北省定州市北城区东大街</div>
            <div class="lefttitle">
              电话：
              17603070223

            </div>
            <div class="lefttitle">
              邮箱：
              microsupermarket@126.com
            </div>
          </div>
          <div class="codebox">
            <div class="code"></div>
            <div class="more">
              扫描二维码
              了解更多
            </div>
          </div>
        </div>
        <div class="bbei">
          <div class="hehuo">
            <div class="">
              鸣谢：
            </div>
            <div class="huobox">东莞市华旺实业有限公司</div>
            <div class="huobox">广东哈一代玩具股份有限公司</div>
            <div class="huobox">浙江年年好喜庆用品超级工厂</div>
          </div>
          <div>冀ICP备:<a href="https://beian.miit.gov.cn" target="_blank">2021025810号</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  name: "index",
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    fatherMethod(type) {
     // var het = this.JQ('#header').height();
      console.log('111-----'+type);
      if (type == 0) {
        $("html,body").animate({
          scrollTop: 0
        }, 1000);
      } else {
        $("html,body").animate({
          scrollTop: parseFloat($('#box' + type).offset().top) - 61
        }, 1000);
      }

    }
  },

}
</script>

<style scoped>
.home {
  width: 100%;
}
.header {
  height: 61px;
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}
.header .headerbox {
  margin: 0 auto;
  width: 1000px;
  height: 100%;
}
.header .headerbox .headerwapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.header .headerbox .headerwapper .logo {
  width: 140px;
  height: 34px;
  background-image: url(../assets/images/img14.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.header .headerbox .headerwapper .menuslist {
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.header .headerbox .headerwapper .menuslist .menusbox {
  font-size: 10px;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: #333333;
  margin-right: 26px;
  padding: 10px;
  cursor: pointer;
}
.header .headerbox .headerwapper .menuslist .menusbox.active {
  background: #1d8c8e;
  font-size: 10px;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: white;
}
.header .headerbox .headerwapper .menuslist .menusbox:last-child {
  margin-right: 0;
}
.header .headerbox .headerwapper .teacherlogin {
  background: #1d8c8e;
  border-radius: 13px;
  font-size: 10px;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: white;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}
.banner {
  height: 487px;
  width: 100%;
  margin-top: 61px;
}
.banner .bannerimg {
  height: 487px;
}
.banner .bannerimg img {
  height: 100%;
  width: 100%;
}
.home .homebox {
  width: 1000px;
  margin: 0 auto;
}
.home .homebox .wapper {
  padding-top: 77px;
}
.home .homebox .wapper .title {
  font-size: 36px;
  font-family: xiaowei;
  font-weight: 400;
  color: #1d8c8e;
  text-align: center;
}
.home .homebox .wapper .des {
  text-align: center;
  font-size: 15px;
  font-family: xiaowei;
  font-weight: bold;
  color: #404040;
  margin-top: 20px;
}
.home .homebox .wapper .des2 {
  font-size: 15px;
  font-family: xiaowei;
  font-weight: bold;
  color: #404040;
  margin-top: 14px;
  text-align: center;
}
.home .homebox .wapper .linebox {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .homebox .wapper .linebox .line {
  width: 53px;
  height: 3px;
  background: #1d8c8e;
}
.home .homebox .wapper .boxlist {
  margin-top: 70px;
  display: flex;
  align-items: center;
}
.home .homebox .wapper .boxlist .boxflex {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home .homebox .wapper .boxlist .boxflex .boxflexicon1 {
  width: 90px;
  height: 90px;
  background-image: url(../assets/images/img02.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home .homebox .wapper .boxlist .boxflex .boxflexicon2 {
  width: 90px;
  height: 90px;
  background-image: url(../assets/images/img06.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home .homebox .wapper .boxlist .boxflex .boxflexicon3 {
  width: 90px;
  height: 90px;
  background-image: url(../assets/images/img04.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home .homebox .wapper .boxlist .boxflex .boxflexicon4 {
  width: 90px;
  height: 90px;
  background-image: url(../assets/images/img03.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home .homebox .wapper .boxlist .boxflex .boxflextitle {
  margin-top: 58px;
  font-size: 25px;
  font-family: xiaowei;
  font-weight: 400;
  color: #333333;
}
.home .homebox .wapper .title2 {
  font-size: 36px;
  font-family: xiaowei;
  font-weight: 400;
  color: #1d8c8e;
  text-align: center;
  margin-top: 78px;
}
.home .homebox .wapper .statuylist {
  display: flex;
  align-items: center;
  margin-top: 73px;
}
.home .homebox .wapper .statuylist .statuylistbox {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon {
  width: 240px;
  height: 315px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 13px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin: 0px auto;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .sailm {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .sailm .sailemicon1 {
  width: 90px;
  height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/images/img01.png);
  border-radius: 50%;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .sailm .sailemicon2 {
  width: 90px;
  height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /*background-image: url(../assets/images/img12.png);*/
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .sailm .sailemicon3 {
  width: 90px;
  height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /*background-image: url(../assets/images/img13.png);*/
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .sailm .sailemicon4 {
  width: 90px;
  height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /*background-image: url(../assets/images/img05.png);*/
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .listfooter {
  background: rgba(255, 255, 255, 0.3);
  font-size: 23px;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: white;
  padding: 13px 0;
  text-align: center;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .boxmeng {
  height: 100%;
  width: 100%;
  position: absolute;
  text-align: center;
  top: 0;
  left: -300px;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .boxmeng .mengpadding {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .boxmeng .mengpadding .mengtitle {
  font-size: 15px;
  font-family: xiaowei;
  font-weight: 400;
  color: white;
  line-height: 40px;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon .boxmeng .mengpadding .mengline {
  width: 59px;
  height: 4px;
  background: white;
  margin-top: 57px;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon:hover .boxmeng {
  left: 0;
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon1 {
  background-image: url(../assets/images/img15.png);
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon2 {
  background-image: url(../assets/images/img17.png);
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon3 {
  background-image: url(../assets/images/img16.png);
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon4 {
  background-image: url(../assets/images/img18.png);
}
.home .homebox .wapper .statuylist .statuylistbox .listboximgicon:last-child {
  margin-right: 0;
}
.home .homebox .wapper .servicelist {
  margin-top: 50px;
  display: flex;
  /*align-items: flex-end;*/
}
.home .homebox .wapper .servicelist .serviceicon {
  width: 350px;
  height: 318px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/images/img10.png);
}
.home .homebox .wapper .servicelist .serviceright {
  flex: 1;
  margin-left: 120px;
}
.home .homebox .wapper .servicelist .serviceright .righttitle {
  font-size: 24px;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: #1d8c8e;
}
.home .homebox .wapper .servicelist .serviceright .rightdexs {
  margin-top: 10px;
  font-size: 14px;
  font-family: xiaowei;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 40px;
}
.home .homebox .wapper .servicelist .serviceright .rightdexs p {
  margin: 0;
  padding: 0;
}
.home .homebox .wapper .servicelist .serviceright .buttonbox {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.home .homebox .wapper .servicelist .serviceright .buttonbox .buttons {
  background: #1d8c8e;
  box-shadow: 0px 3px 8px 0px rgba(17, 88, 232, 0.44);
  font-size: 18px;
  font-family: xiaowei;
  font-weight: 400;
  color: white;
  padding: 8px 18px;
}
.home .homebox .wapper .servicelist .serviceright .rightfoodeds {
  font-size: 13px;
  font-family: xiaowei;
  font-weight: 400;
  color: #333333;
  margin-top: 17px;
  width: 490px;
  line-height: 22px;
}
.home .homebox .wapper .peoplelist {
  margin-top: 15px;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.home .homebox .wapper .peoplelist .peolplebox {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  flex-direction: column;
  width: 50%;
}
.home .homebox .wapper .peoplelist .peolplebox .peopleicon1 {
  width: 258px;
  height: 246px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

}
.home .homebox .wapper .peoplelist .peolplebox .peopleicon2 {
  width: 258px;
  height: 246px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

}
.home .homebox .wapper .peoplelist .peolplebox .peopleicon3 {
  width: 258px;
  height: 246px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

}
.home .homebox .wapper .peoplelist .peolplebox .peoplename {
  margin-top: 36px;
  font-size: 18px;
  font-family: xiaowei;
  font-weight: 400;
  color: #1a1a1a;
}
.home .homebox .wapper .peoplelist .peolplebox .zhileist {
  margin-top: 27px;
  padding: 0 23px 0 0;
}
.home .homebox .wapper .peoplelist .peolplebox .zhileist .zhibox {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}
.home .homebox .wapper .peoplelist .peolplebox .zhileist .zhibox .zhiboxicon {
  width: 10px;
  height: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/images/img09.png);
}
.home .homebox .wapper .peoplelist .peolplebox .zhileist .zhibox .zhiboxicon2 {
  width: 10px;
  height: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/images/img08.png);
}
.home .homebox .wapper .peoplelist .peolplebox .zhileist .zhibox .zhidestitle {
  flex: 1;
  font-size: 13px;
  font-family: xiaowei;
  font-weight: bold;
  color: #595959;
  margin-left: 5px;
  line-height: 22px;
}
.home .homebox .wapper .peoplelist .peolplebox .zhileist .zhibox .zhidestitle p {
  margin: 0;
  padding: 0;
}
.home .homebox .wapper .peoplelist .peolplebox .zhileist .zhibox:last-child {
  margin-bottom: 0;
}
.home .homebox .wapper .cpompany {
  margin-top: 53px;
  display: flex;
  align-items: flex-start;
}
.home .homebox .wapper .cpompany .conpmayicon {
  margin-top: 20px;
  width: 332px;
  height: 231px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/images/img11.png);
}
.home .homebox .wapper .cpompany .conpmades {
  flex: 1;
  margin-left: 76px;
  margin-right: 70px;
  font-size: 15px;
  font-family: xiaowei;
  font-weight: bold;
  color: #666666;
  line-height: 32px;
}
.home .homebox .wapper .wenhua {
  margin-top: 61px;
}
.home .homebox .wapper .wenhua .wenhuabox {
  display: flex;
  align-items: flex-start;
}
.home .homebox .wapper .wenhua .wenhuabox .wenhuaboxleft {
  flex: 1;
}
.home .homebox .wapper .wenhua .wenhuabox .wenhuaboxleft .lefttitle {
  font-size: 30px;
  font-family: xiaowei;
  font-weight: bold;
  color: #333333;
}
.home .homebox .wapper .wenhua .wenhuabox .wenhuaboxleft .leftlist {
  margin-top: 28px;
}
.home .homebox .wapper .wenhua .wenhuabox .wenhuaboxleft .leftlist .leftlisttitle {
  font-size: 18px;
  font-family: xiaowei;
  font-weight: 400;
  color: #404040;
  margin-bottom: 24px;
}
.home .homebox .wapper .wenhua .wenhuabox .wenhuaboxleft .leftlist .leftlisttitle:last-child {
  margin-bottom: 0;
}
.home .homebox .wapper .wenhua .wenhuabox .wenhuaboxleft .listfendes {
  font-size: 16px;
  font-family: xiaowei;
  font-weight: 400;
  color: #404040;
  line-height: 30px;
}
.home .homebox .wapper .wenhua .wenhuabox .wenhuaboxleftpm {
  padding: 80px 40px 0 40px;
}
.home .homebox .wapper .wenhua .wenhuabox .wenhuaboximg {
  margin-top: 15px;
  width: 515px;
  height: 353px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/images/img22.png);
}
.home .homebox .wapper .wenhua .wenhuabox .wenhuaboximg2 {
  margin-top: 15px;
  width: 515px;
  height: 353px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/images/img23.png);
}
.home .homebox .wapper .wenhua .wenhuaboxpos {
  position: relative;
}
.home .homebox .wapper .wenhua .wenhuaboxmt {
  margin-top: -60px;
}
.home .homebox .wapper .map {
  height: 375px;
  widows: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/images/img24.png);
  margin-top: 13px;
}
.home .homebox .wapper .mapdes {
  font-size: 15px;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: #404040;
  margin-top: 48px;
}
.footer {
  background: #1b1b1b;
  margin-top: 50px;
}
.footer .footerbox {
  width: 1000px;
  margin: 0 auto;
}
.footer .footerbox .box {
  display: flex;
  padding: 50px 0;
}
.footer .footerbox .box .boxleft {
  flex: 1;
}
.footer .footerbox .box .boxleft .lefttitle {
  font-size: 18px;
  font-family: xiaowei;
  font-weight: 400;
  color: white;
  margin-bottom: 15px;
}
.footer .footerbox .box .boxleft .lefttitle:last-child {
  margin-bottom: 0;
}
.footer .footerbox .box .codebox .code {
  width: 120px;
  height: 120px;
  background-image: url(../assets/images/img25.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.footer .footerbox .box .codebox .more {
  font-size: 12px;
  font-family: xiaowei;
  font-weight: 400;
  color: white;
  margin-top: 20px;
}
.codebox{
  text-align: center;
}
.bbei{
  display: flex;
  font-size: 14px;
  font-family: xiaowei;
  font-weight: 400;
  padding-bottom: 15px;
  color: white;
}
.bbei a{
  color: white;
  text-decoration:none;
}
.hehuo{
flex: 1;
  display: flex;
}
.huobox{
  margin-right: 15px;
}
</style>